/******************************************************************

600以上の画面ススタイル

******************************************************************/

.flex {
    flex-direction: row;
}

// ナンバー
.number-image {
	margin: 0;
}

.mobile-menu {
    &__li {
        width: 65%;
    }
}

.header {
    &__inner {
         padding: 0 50px;
    }
}

.hero {

    &__images {
    
        &::before {
          padding-top: 60%;
        }
    }

    &__title {
   
       &-japan {
         font-size: 40px;
       }
       &-syokunin {
         font-size: 40px;
       }
       &-under {
         font-size: 20px;
       }
    }
}

.main {
    margin-bottom: -150px;
    &__br {
        display: none;
    }

    &__inner {
        flex-wrap: nowrap;
    }

    &__text {
        margin-right: 20px;
        text-align: left;
        &-top {
            &-title {
                text-align: left;
                margin: 0;
            }
            &-des {
                font-size: 13px;
            }
        }
        &-under {
            &-title {
                text-align: left;
                margin: 0;
            }
            &-des {
                font-size: 13px;
            }
        }
    }

    &__logoimages {
        width: 45vw;
    }
    &__underlogoimages {
        width: 45vw;
        position: relative;
        float: right;
        text-align: right;
        right: -15vw;
        top: 2vw;
        
    }
    &__images {
        bottom: 0;
    }
    &__image {
        &-map {
            top: 10vw;
            width:45vw;
            opacity: 1;
        }
        &-shizuoka {
            width: 18vw;
            top: 15vw;
            left: 1vw;
            display: block;
        }
        &-yajirushi {
            width: 8vw;
            top: 21vw;
            left: 17vw;
            display: block;
       }
  
       &-fuji {
         top: 23vw;
         left: 25vw;
         width: 50%;
       }
    }
}

.syokunin {
    margin-bottom: 0;

    &__wrapper {
        bottom: 0;
    }

    &__backimage {
        
      }
    
    &__top {
       align-items: center;

       &-sushi {
           display: flex;
           flex-direction: row-reverse;
           align-items: center;
       }
      
    }

    &__numberimages {
        position: relative;
    }

    &__numberimage {
        width: 40vw;
        position: relative;
        top: 50px;
    }
    
    &__tatamiimages {
        display: inline-block;
        width: 40vw;
        padding-top: 10vw;
    }
    &__center {
        flex-direction: column;
        padding-top: 50px;

       
    }
    &__syokunin {
		font-size: 30px;
        position: relative;
        top: 1vw;
	}
	&__name {
		font-size: 50px;

        &-wrapper {
            text-align: left;
            margin-left: 30px;
            .syokunin:nth-child(2n) & {
		        margin-right: 30px;
                margin-left: 0;
		
	        }
        }
    
    }

    &__left {
        .syokunin:nth-child(2n) & {
    	flex-direction: row-reverse;

	}
    }

    &__text {
        padding: 30px 20px;
    }
	&__englishsyokunin {
		font-size: 20px;
	}
	&__englishname {
		font-size: 26px;
	}

    &__logo {
        display: block;
        font-size: 57px;
        color: #FFFFFF;
        position: relative;
    }
    
    &__syokuninimages {
        width:100%;
    }

    &__topimages {
		display: none;
	}
    &__bottomimages {
    	
		
	}

    &__douga-images {
        width: 100%;
        height: 315;
        display: inline-block;
        
    }
}

.sushi {

    &__backimages {
        transform: rotate(180deg);
    }

    &__backimage {
        background-position:center top;
        width: 100%;
        bottom: 0%;
        transform: rotate(180deg);
      }
    
    &__top {
       align-items: center;
       display: flex;
    flex-direction: row-reverse;
    }

    &__left {
        width: 50%;
        display: flex;
        flex-direction: row-reverse;
    }
    &__right {
        width: 50%;
    }

    &__numberimage {
        width: 50vw;
        position: relative;
        top: 50px;
    }

    &__tatamiimages {
        display: inline-block;
        width: 40vw;
        padding-top: 10vw;
    }
    &__center {
        flex-direction: column;
        padding-top: 50px;
    }
    &__syokunin {
		font-size: 20px;
        position: relative;
        top: 1vw;
	}
	&__name {
		font-size: 23px;
       
        &-wrapper {
            width: 50vw;
            position: relative;
            top: 1vw;
            left: -2vw;
            text-align: center;
            display: flex;
            flex-direction: column;

	    }
    }
	&__englishsyokunin {
		font-size: 15px;
	}
	&__englishname {
		font-size: 14px;
	}

    &__logo {
        display: block;
        font-size: 57px;
        color: #FFFFFF;
        opacity: 0.5;
    }
    
    &__syokuninimages {
        width:100%;
    }
    &__text {
        &-wrapper {
            margin-right: 20px
        }
    }
}


.footer {
   height: 110px;
   margin-top: 0;
}



