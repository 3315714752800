.syokunin {
    &__backimage {

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image:linear-gradient(to right, transparent 0%,  #ebe9d4 100% );

        .syokunin:nth-child(2n) & {
             background-image:linear-gradient(to left, transparent 0%,  #ebe9d4 100% );
           
        }

        }
    }
}