
// ナンバー
.text-image {
	position: relative;
	width: 350px;
	margin: 0 auto
}

.text-image img {	
	width: 100%;
	margin-bottom: 15px;
}
  
.text-image p {
	top: 27%;
	left: 34%;
	font-size: 50px;
	color: black;
}

.youtube {
    margin: 0 auto !important;
    width: 70vw !important;
    position: relative;
    top: 3vw;
}

.main {
    &__image {
        &-map {
            top: 5vw;
        }
    }
    &__text {
        &-top {
            &-des {
                font-size: 14px;
            }
        }
        &-under {
            &-des {
                font-size: 14px;
            }
        }
		
    }
    &__underlogoimages {
       top: 0vw;
    }
}

.syokunin {

    &__syokuninimages {
        background-position:center;
		 margin-bottom: 0;

		&::before {
			content: '';
			display: block;
			padding-top: 120%;
		}

        &-soba {
            width: 50%;
        }
        &-sushi {
            width: 50%;
        }
    }
    &__center {
        flex-direction: row-reverse;

		 .syokunin:nth-child(2n) & {
		    flex-direction: row;
	    }

    }
    &__numberimages {
        width: 40vw;
    }

    &__logo {
        display: block;
        font-size: 100px;
        color: #FFFFFF;
    }
    &__text {
       font-size: 14px;
       padding: 40px 50px;
        &-wrapper {
            width: 55%;
            margin-right: 20px;
			&-back {
			width: 100%;
			margin: 0;
		}
        }
    }
    &__left {
        align-items: center;
    }
    &__syokuninimages {
        width:40%;
    }

    &__topimages {
		display: block;
	}
    &__bottomimages {
    	left: 63%;
		.syokunin:nth-child(2n) & {
    	right: 63%;

	}
	}
    &__douga-images {
        width: 60%;
        height: 315;
    }
	&__position {
		&-right {
			background-position: 80%;
		}
	}
}


.sushi {
    position: relative;
    margin-bottom: 150px;

  &__backimages {
	  display: flex;
	  flex-direction: row-reverse;
	position: absolute;
	width: 100%;
	height: 90%;
	z-index: 1;
	background-color: rgba(224, 220, 182, 0.5);
	mix-blend-mode: multiply;
	mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 80%, transparent 100%);

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #E0DCB6;
		mask-image:linear-gradient(to left, #E0DCB6 50%, transparent 100%);
		}
   }

  &__backimage {
      background-image: url(../images/sushi_haikei.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position:25%;
      width: 100%;
      height: 70%;
      position: absolute;
      bottom: 25%;
      right: 0;
      mask-image: linear-gradient(to bottom, transparent 0%, black 10% black 90%, transparent 100%);
    }

    &__inner {

      position: relative;
      top: 80px;
      z-index: 2;
    }

	&__top {
	    width: 100%;
	    text-align: center;
		
	}
	&__left {
	   display: flex;
	   flex-direction: row-reverse;
	  
	}
	&__numberimage {
	    width: 30%;
		padding-bottom: 20px;
	}
	&__tatamiimages {
	   display: none;
	}
	&__center {
	   flex-direction: row;
       justify-content: space-between;
       align-items: center;
	   position: relative;
    }
	&__syokunin {
		color: #9D8121;
		font-size: 22px;
    	font-family: 'Noto Serif JP', serif;
    	font-weight: bold;
	}
	&__name {
		font-size: 28px;
    	font-family: 'Noto Serif JP', serif;
    	font-weight: bold;

    	&-wrapper {
    	  margin-bottom: 30px;
		  text-align: center;
    	}
		
	}
	&__englishsyokunin {
		color: #9D8121;
		font-size: 16px;
    	font-family: 'Noto Serif JP', serif;
    	font-weight: bold;
	}
	&__englishname {
		font-size: 20px;
    	font-family: 'Noto Serif JP', serif;
    	font-weight: bold;
		
	}

	&__logo {
		
    	font-family: 'Spectral', serif;
	}
	&__syokuninimages {
    	margin-bottom: 30px;
	  background-image: url(../images/sushi_person.jpg);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;
	  width: 100%;
	  &::before {
		  content: '';
		  display: block;
		  padding-top: 70%;
	  }
	}

  &__text {
	  width: 85%;
	  margin: 30px auto;
	  &-wrapper {
		  background-image: url(../images/text_bak.png);
		  background-position: center;
		  background-repeat: no-repeat;
		  background-size: cover;
		  width: 105vw;
	  }

      &-japan {
          display: block;
          margin-bottom: 30px;
      }
    }

	&__douga{
		&-images {
			margin: 0 auto;
		width: 100%;
		height: 315px;
		z-index: 2;
		}
	}

	&__topimages {
		display: none;
	}


	&__bottomimages {
		width: 70vw;
		display: inline-block;
    	position: relative;
    	top: -13px;
    	right: -40vw;
    	z-index: -1;
	}
}


